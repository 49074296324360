import { createToken, createTokenizer, resolveTokens } from '@solid-primitives/jsx-tokenizer';
import { For, Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconHouse01 } from '@troon/icons/house-01';
import { IconChevronRight } from '@troon/icons/chevron-right';
import { LinkButton } from './link';
import type { Component, ParentProps } from 'solid-js';

export function Breadcrumbs(props: ParentProps<{ appearance?: 'current'; omitHome?: boolean }>) {
	const tokens = resolveTokens(BreadcrumbTokenizer, () => props.children);

	return (
		<>
			<div class="hidden">
				<IconHouse01 />
				<IconChevronRight />
			</div>
			<ol
				class="flex max-w-full items-center gap-0 truncate text-sm md:gap-2"
				itemscope
				itemtype="https://schema.org/BreadcrumbList"
			>
				<Show when={!props.omitHome}>
					<li>
						<LinkButton
							href="/"
							itemprop="item"
							appearance={props.appearance}
							class={!props.appearance ? 'text-neutral-900' : undefined}
						>
							<IconHouse01 />
							<span class="sr-only" itemprop="name">
								Troon Home
							</span>
							<meta itemprop="position" content={'0'} />
						</LinkButton>
					</li>
				</Show>
				<For each={tokens()}>
					{(item, i) => (
						<>
							<Show when={!props.omitHome || i() > 0}>
								<li aria-hidden class="shrink-0 grow-0">
									<IconChevronRight />
								</li>
							</Show>
							<li
								class={twJoin('inline-flex max-w-sm grow-0 items-center truncate', i() !== 0 && 'shrink')}
								itemprop="itemListElement"
								itemscope
								itemtype="https://schema.org/ListItem"
							>
								<LinkButton
									href={item.data.href!}
									itemprop="item"
									class={twJoin(
										'inline-block text-sm',
										i() !== 0 && 'truncate',
										!props.appearance && 'text-neutral-900',
									)}
									appearance={props.appearance}
								>
									<span itemprop="name">{item.data.children}</span>
									<meta itemprop="position" content={`${i() + (props.omitHome ? 1 : 2)}`} />
								</LinkButton>
							</li>
						</>
					)}
				</For>
			</ol>
		</>
	);
}

export const BreadcrumbTokenizer = createTokenizer<Token>({ name: 'Breadcrumb' });

export const BreadcrumbItem = createToken(BreadcrumbTokenizer, (props) => ({
	...props,
	type: 'breadcrumb',
})) as Component<Omit<Token, 'type'>>;
type Token = ParentProps<{ type: 'breadcrumb'; href: string }>;
